import React, {useEffect} from "react";
import {navigate} from "gatsby";

const indexPage: React.FC = () => {

    useEffect(() => {
        navigate('/rechtsgebiete/familienrecht/', {
            state: 301,
            replace: false
        });
    });

    return (
        <></>
    )
}
export default indexPage;
